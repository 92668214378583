import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import ITemplateResponse from '@/modules/offer-template/interfaces/template-response.interface'
import { IOfferInformationItem } from '@/modules/offer/interfaces/offer-response.interface';

export default class Template extends Model<IModelResponse> {
  static ALIAS: string = 'template'

  title!: string
  total!: string
  total_vat!: string
  total_with_vat!: string
  discount_type!: string
  discount!: number
  discount_percentage!: number
  information!: IOfferInformationItem[]
  notes!: string

  transform(data: ITemplateResponse): void {
    super.transform(data)

    this.title = data.title
    this.total = data.total || '0.00'
    this.total_vat = data.total_vat || '0.00'
    this.total_with_vat = data.total_with_vat || '0.00'
    this.discount_type = data.discount_type || 'none'
    this.discount = data.discount || 0
    this.discount_percentage = data.discount_percentage || 0
    this.information = data.information || []
    this.notes = data.notes
  }
}
