import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IOfferResponse, { IOfferInformationItem } from '@/modules/offer/interfaces/offer-response.interface'
import moment from 'moment'

export default class Offer extends Model<IModelResponse> {
  static ALIAS: string = 'offer'

  title!: string
  client!: Object
  client_id!: number
  date!: string
  due_date!: string
  number!: number
  author!: Object
  information!: IOfferInformationItem[]
  manager!: Object
  manager_id!: number
  total!: string
  locale!: string
  total_vat!: string
  total_with_vat!: string
  notes!: string
  tinyUrl!: string
  status!: string
  report_type!: string
  psc_count!: number
  hours_count!: number
  discount_type!: string
  discount!: number
  discount_percentage!: number
  template_id!: number | null
  request_id!: number | undefined

  transform(data: IOfferResponse): void {
    super.transform(data)

    this.title = data.title
    this.client = data.client
    this.client_id = data.client_id
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.due_date = moment(data.due_date).format('YYYY-MM-DD')
    this.number = data.number
    this.author = data.author
    this.manager = data.manager
    this.manager_id = data.manager_id
    this.information = data.information || []
    this.locale = data.locale
    this.total = data.total || '0.00'
    this.total_vat = data.total_vat || '0.00'
    this.total_with_vat = data.total_with_vat || '0.00'
    this.notes = data.notes
    this.status = data.status
    this.report_type = data.report_type
    this.psc_count = data.psc_count
    this.hours_count = data.hours_count
    this.template_id = data.template_id || null
    this.discount_type = data.discount_type || 'none'
    this.discount = data.discount || 0
    this.discount_percentage = data.discount_percentage || 0
    this.request_id = data.request_id || undefined
    if (data.tiny_url) this.tinyUrl = data.tiny_url
  }
}
