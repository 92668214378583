






































































































































































































































import { Component, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import { Route } from 'vue-router'
import Payment from '../payment/models/payment.model'
import moment from 'moment'
import fileBuffer from '@/shared/helpers/file-buffer-helper'
import { InvoiceStatusKeysData } from '@/shared/configs/invoice/invoice.config'
import PaymentsCreate from '@/modules/payment/PaymentsCreate.vue';
import InvoiceDelete from '@/modules/invoices/InvoicesDelete.vue';
import fetchInvoiceData from '@/shared/helpers/fetch-invoice-data';
import PaymentsEdit from '@/modules/payment/PaymentsEdit.vue';
import PaymentDelete from '@/modules/payment/PaymentsDelete.vue';
import TableLoader from '@/shared/components/data-table/TableLoader.vue';
import InvoiceServicesTable from '@/modules/invoices/tables/InvoiceServicesTable.vue';
import StickyTableHeader from '@/shared/components/data-table/StickyTableheader.vue';

interface fieldType {
  key: string
  label: string
  sortable?: boolean
}

@Component({
  components: { InvoiceServicesTable, TableLoader, PaymentsCreate, PageHeader },
  methods: { __ }
})
export default class InvoicePage extends StickyTableHeader {
  invoice: Invoice | any = null
  downloaded: boolean = true
  loading: boolean = true
  GeneralRoutes = GeneralRoutes
  copyText: string = __('views.offers.offer.copy')

  fields: fieldType[] = [
    { key: 'title', label: __('views.invoices.table.title'), sortable: true },
    { key: 'amount', label: __('views.invoices.table.amount'), sortable: true },
    { key: 'unit', label: __('views.invoices.table.unit'), sortable: true },
    { key: 'vat', label: __('views.invoices.table.vat'), sortable: true },
    { key: 'discount_percentage', label: __('views.invoices.table.discount'), sortable: true },
    { key: 'price_per_unit', label: __('views.invoices.table.unit-price'), sortable: true },
    { key: 'total', label: __('views.invoices.table.total'), sortable: true },
    { key: 'total_vat', label: __('views.invoices.table.total-vat'), sortable: true },
    { key: 'total_with_vat', label: __('views.invoices.table.total-with-vat'), sortable: true }
  ]

  paymentFields: fieldType[] = [
    { key: 'date', label: __('views.invoices.table.date'), sortable: true },
    { key: 'full_payment', label: __('views.invoices.table.full-payment'), sortable: true },
    { key: 'amount', label: __('views.invoices.table.amount'), sortable: true },
    { key: 'notes', label: __('views.invoices.table.notes'), sortable: true },
    { key: 'action', label: '', sortable: false }
  ]

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(newVal: Route): void {
    if (newVal.name === GeneralRoutes.invoice && this.$refs.paymentsTable && this.$refs.itemsTable) {
      this.getData()
    }
  }

  created(): void {
    fetchInvoiceData(this, 'prepaid', GeneralRoutes.prepaidInvoice)
      .then((item: any) => {
        this.invoice = this.formatData(item.data.data)
        this.$root.$emit('headingChange', {
          heading: __('views.invoices.invoice.title', { invoices: this.invoice.number }),
          badge: this.invoice.status
        })
      })
      .finally(() => {
        this.loading = false
        this.setStickyHeader()
      })

    this.$root.$on('formSuccess', this.getData)
  }

  getDiscountAmount() {
    if (!this.invoice) { return '' }
    if (this.invoice.discount_type === 'discount') {
      return `${this.invoice.discount} €`
    }
    if (this.invoice.discount_type === 'discount_percentage') {
      return `${this.invoice.discount_percentage} %`
    }
  }

  getData(): void {
    this.loading = true
    http
      .get(`/invoices/${this.$route.params.id}`)
      .then((item: IResponse<IModelResponse>) => {
        this.invoice = this.formatData(item.data.data)
        this.$root.$emit('headingChange', {
          heading: __('views.invoices.invoice.title', { invoices: this.invoice.number }),
          subheading: __('views.invoices.invoice.subtitle', { date: this.invoice.date }),
          subheadingNote: __('views.invoices.invoice.created-by', { name: this.invoice.manager.full_name })
        })
      })
      .finally(() => {
        this.loading = false
      })
  }

  editInvoice(): void {
    this.$router.push({ name: GeneralRoutes.invoicesEdit, params: { id: this.$route.params.id } })
  }

  openPaymentModal(): void {
    this.$store.commit('setModalDialog', {
      component: PaymentsCreate,
      id: this.invoice.id,
      props: {
        title: __('views.payments.create.title', { invoice: this.invoice.number }),
        onSuccess: () => {
          this.getData()
        }
      },
      details: {
        clientID: this.invoice.client_id,
        totalWithVat: this.invoice.total_with_vat,
        leftToPay: this.invoice.left_pay
      }
    })
  }

  async copy(link: string): Promise<void> {
    await navigator.clipboard.writeText(link).then(() => {
      this.copyText = `${__('views.offers.offer.copied')}!`
      setTimeout(() => {
        this.copyText = __('views.offers.offer.copy')
      }, 3000)
    })
  }

  openEditModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentsEdit,
      id: row.item.id,
      details: {
        clientId: row.item.client_id,
        invoiceId: row.item.invoice_id,
        paymentId: row.item.payment_id
      },
      props: {
        title: __('views.payments.edit.title')
      }
    })
  }

  openPaymentDeleteModal(row: any): void {
    this.$store.commit('setModalDialog', {
      component: PaymentDelete,
      id: row.item.id,
      details: {
        invoiceId: row.item.invoice_id,
        paymentId: row.item.id,
        paymentDate: row.item.date
      },
      props: {
        title: __('views.payments.delete.title', { payment: row.item.date }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }

  openDeleteModal(): void {
    this.$store.commit('setModalDialog', {
      component: InvoiceDelete,
      id: this.invoice.id,
      props: {
        title: __('views.invoices.delete.title', { invoice: this.invoice.number }),
        size: 'md',
        submitLabel: __('form.delete'),
        onSuccess: () => {
          this.getData()
        }
      }
    })
  }

  approveInvoice(): void {
    http.get(`invoices/${this.$route.params.id}/approve`).then(() => {
      this.getData()
    })
  }

  deletePayment(item: any): void {
    this.$router.push({
      name: GeneralRoutes.paymentsDelete,
      query: this.$route.query,
      params: { payment_id: item.item.id.toString(), invoice_id: item.item.invoice_id }
    })
  }

  downloadPDF(): void {
    this.downloaded = false
    http
      .get(`invoice-pdf/${this.$route.params.id}`, { responseType: 'blob' })
      .then(response => {
        fileBuffer.downloadFileBuffer(response)
      })
      .finally(() => {
        this.downloaded = true
      })
  }

  payment(): void {
    this.$router.push({ name: GeneralRoutes.paymentCreate, params: { id: this.$route.params.id } })
  }

  formatData(data: Invoice): any {
    return {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD'),
      due_date: moment(data.due_date).format('YYYY-MM-DD'),
      payments: data.payments.map((payment: Payment) => ({
        ...payment,
        date: moment(payment.date).format('YYYY-MM-DD')
      }))
    }
  }

  invoiceStatus(statusKey: string): string {
    return InvoiceStatusKeysData[statusKey]
  }
}
