import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IServiceResponse from '@/modules/service/interfaces/service-response.interface'

export default class Service extends Model<IModelResponse> {
  static ALIAS: string = 'service'

  title!: string
  amount!: string | number | null
  pricePerUnit!: string | number | null
  totalWithVat!: string | number
  discountPercentage!: number
  pricePerUnitWithVat!: string | number
  unit!: string
  vat!: string | number | null

  transform(data: IServiceResponse): void {
    super.transform(data)

    this.title = data.title
    this.amount = data.amount
    this.pricePerUnit = data.price_per_unit
    this.totalWithVat = data.total_with_vat
    this.discountPercentage = data.discount_percentage
    this.pricePerUnitWithVat = data.price_per_unit_with_vat
    this.unit = data.unit
    this.vat = data.vat
  }
}
