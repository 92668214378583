import http from '@/shared/helpers/http'
import { AxiosResponse } from 'axios'
import { IOfferPdfOption } from '@/modules/offer/interfaces/offer-response.interface';
import Offer from '@/modules/offer/models/offer.model';
import Template from '@/modules/offer-template/models/template.model';

export default class OfferService {
  static async updateStatus(offerId: number, data: any): Promise<AxiosResponse> {
    return http.put(`offers/${offerId}/status`, data).then((response: any) => response.data)
  }

  static async getPdfOptions(): Promise<IOfferPdfOption[]> {
    return http.get('offer-pdf/options').then((response: any) => response.data.data)
  }

  static async downloadPdf(offerId: string, type: string, locale: string): Promise<AxiosResponse> {
    return http.get(`offer-pdf/${offerId}/${type}/${locale}`, { responseType: 'blob' })
  }

  static async createOffer(payload: Offer): Promise<Offer> {
    return http.post('offers', payload).then(response => response.data.data)
  }

  static async updateOffer(payload: Offer, id: number): Promise<Offer> {
    return http.put(`offers/${id}`, payload).then(response => response.data.data)
  }

  static async getOffer(id: number): Promise<Offer> {
    return http.get(`offers/${id}`).then(response => response.data.data)
  }

  static async createOfferTemplate(payload: Template): Promise<Template> {
    return http.post('offer-templates', payload).then(response => response.data.data)
  }

  static async updateOfferTemplate(payload: Template, id: number): Promise<Template> {
    return http.put(`offer-templates/${id}`, payload).then(response => response.data.data)
  }

  static async getOfferTemplate(id: number): Promise<Offer> {
    return http.get(`offer-templates/${id}`).then(response => response.data.data)
  }
}
