




































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/layout/PageHeader.vue'
import __ from '@/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import { FieldSizes } from '@/shared/classes/components/form/field'
import Invoice from '@/modules/invoices/models/invoice.model'
import { GeneralRoutes } from '@/router/routes/general'
import http from '@/shared/helpers/http'
import IResponse from '@/shared/interfaces/response.interface'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import _ from 'lodash'
import { InvoiceStatusKeys, InvoiceTypes } from '@/shared/configs/invoice/invoice.config'
import getAll from '@/shared/configs/vuex/get-all.config'
import { mapGetters } from 'vuex'
import User from '../user/models/user.model'
import { UserGetters } from '@/store/modules/user/user.getters'
import InvoicesService from '@/services/InvoicesService';
import SelectOption from '@/shared/classes/components/form/select-option';
import { IServiceItem } from '@/modules/invoices/interfaces/invoice-response.interface';
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';
import LoadingSpinner from '@/shared/components/LoadingSpinner.vue';
import RadioField from '@/shared/components/form/fields/RadioField.vue';

@Component({
  components: { RadioField, LoadingSpinner, PageHeader, Form },
  methods: { __ },
  computed: {
    ...mapGetters({
      user: UserGetters.getUser
    })
  }
})
export default class InvoiceCreate extends Vue {
  FieldSizes = FieldSizes
  loadingNumber: boolean = false
  validNumber: boolean = false
  user!: User
  error: any = null
  data: Invoice = new Invoice({})
  vatOptions: SelectOption[] = []
  unitOptions: SelectOption[] = []
  discountTypes: SelectOption[] = [
    new SelectOption().setKey('none').setTitle(__('views.invoices.form.no_discount')),
    new SelectOption().setKey('discount').setTitle(__('views.invoices.form.sum_discount')),
    new SelectOption().setKey('discount_percentage').setTitle(__('views.invoices.form.percent_discount'))
  ]

  loading: boolean = true

  async submit(): Promise<void> {
    await InvoicesService.createInvoice(this.data)
      .then((invoice: Invoice) => {
        if (invoice.id) this.$router.push({ name: GeneralRoutes.invoice, params: { id: invoice.id.toString() } })
      })
      .catch((error: any) => (this.error = error))
  }

  private debouncedCheckIfValid = _.debounce(() => this.checkIfValid(), 400)

  debouncedValidityCheck() {
    this.debouncedCheckIfValid()
  }

  onItemChange(data:IServiceItem, item:IServiceResponse):void {
    if (typeof data?.amount !== 'undefined') {
      item.amount = data.amount
    }
    if (typeof data?.vat !== 'undefined') {
      item.vat = Number(data.vat)
    }
    if (typeof data?.price_per_unit !== 'undefined') {
      item.price_per_unit = data.price_per_unit
    }
    if (typeof data?.price_per_unit_with_vat !== 'undefined') {
      item.price_per_unit_with_vat = Number(data.price_per_unit_with_vat)
    }
    const foundUnit = this.unitOptions.find(unit => unit.title === data?.unit)
    item.unit = foundUnit?.key
  }

  async getInvoiceItemOptions(): Promise<void> {
    const vatOptions = await InvoicesService.getVatOptions()
    vatOptions.forEach(vatItem => {
      this.vatOptions.push(new SelectOption().setKey(vatItem.percentage).setTitle(vatItem.title))
    })

    const unitOptions = await InvoicesService.getUnitOptions()
    unitOptions.forEach(unitItem => {
      this.unitOptions.push(new SelectOption().setKey(unitItem.title).setTitle(unitItem.title))
    })
  }

  removeInvoiceItem(index: number): void {
    this.data.items.splice(index, 1)
  }

  calculateSums() {
    if (!this.data) {
      return
    }
    let newTotal = 0
    let newTotalWithVat = 0
    let newTotalVat = 0
    const vatRate = (this.data.items.length ? this.data.items[0].vat : 0) || 0
    this.data.items.forEach(item => {
      const itemTotal = Number(item.amount) * Number(item.price_per_unit)
      const itemTotalWithVat = itemTotal * (1 + Number(item.vat) / 100)
      item.total = itemTotal.toFixed(2)

      item.total_with_vat = itemTotalWithVat.toFixed(2)
      newTotal += itemTotal
      newTotalWithVat += itemTotalWithVat
      newTotalVat += (itemTotalWithVat - itemTotal)
    })
    const sumDiscount = this.data.discount_type === 'discount' ? Number(this.data.discount) : 0
    const percentDiscount = this.data.discount_type === 'discount_percentage' ? 1 - (Number(this.data.discount_percentage) / 100) : 1
    this.data.total = ((newTotal - sumDiscount) * percentDiscount).toFixed(2)
    this.data.total_with_vat = ((newTotalWithVat - sumDiscount * (1 + vatRate / 100)) * percentDiscount).toFixed(2)
    this.data.total_vat = ((newTotalVat - sumDiscount * (vatRate / 100)) * percentDiscount).toFixed(2)
  }

  @Watch('data.items', { deep: true })
  @Watch('data.discount_type')
  @Watch('data.discount')
  @Watch('data.discount_percentage')
  onItemsChange(): void {
    this.calculateSums()
  }

  async created(): Promise<void> {
    await getAll(this.$store)
    await this.getInvoiceItemOptions()

    await http
      .get(`invoices/get-next-number/${InvoiceTypes.standard}`)
      .then((response: IResponse<IModelResponse>) => {
        this.loadingNumber = true
        this.data.number = response.data
        this.validNumber = true
      })
      .finally(() => {
        this.loadingNumber = false
      })

    this.data.manager_id = this.user.id
    this.data.locale = 'lt'
    this.data.send_email = true
    this.data.status = InvoiceStatusKeys.unpaid
    this.data.type = InvoiceTypes.standard

    if (this?.$route?.params?.data) {
      const data: any = this.$route.params.data
      this.data.manager_id = data.manager_id ? data.manager_id : this.user.id
      this.data.client_id = data.client_id
      this.data.date = data.date
      this.data.notes = data.notes
      this.data.items = data.items || []
    }

    this.loading = false
  }

  checkIfValid(): any {
    if (!this.loadingNumber && this.data.number) {
      this.loadingNumber = true
      http
        .get(`invoices/check-is-available/${this.data.number}`)
        .then((response: IResponse<IModelResponse>) => {
          if (!response.data) this.validNumber = false
          else this.validNumber = true
        })
        .finally(() => {
          this.loadingNumber = false
        })
    }
  }

  addInvoiceItem() {
    this.data.items.push({ ...this.data.newInvoiceItem })
  }
}
