

































import { Component, Prop } from 'vue-property-decorator'
import AbstractField from '@/shared/components/form/fields/AbstractField.vue'
import SelectOption from '@/shared/classes/components/form/select-option'

@Component
export default class RadioField extends AbstractField {
  @Prop({ default: [] }) private readonly options!: SelectOption[]
}
