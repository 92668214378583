import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/model-response.interface'
import IInvoiceResponse from '@/modules/invoices/interfaces/invoice-response.interface'
import Client from '@/modules/client/models/client.model'
import moment from 'moment'
import Service from '@/modules/service/models/service.model'
import Payment from '@/modules/payment/models/payment.model'
import { InvoiceStatusKeysData } from '@/shared/configs/invoice/invoice.config'
import IServiceResponse from '@/modules/service/interfaces/service-response.interface';

export default class Invoice extends Model<IModelResponse> {
  static ALIAS: string = 'invoice'

  add_sale_deed!: boolean
  active!: string
  client!: Client
  client_id!: number
  author!: Object
  manager!: any
  manager_id!: number
  number!: string
  type!: string
  status!: string
  date!: string
  due_date!: string
  total!: string
  total_with_vat!: string
  locale!: string
  total_vat!: string
  items!: IServiceResponse[]
  notes!: string
  leftPay!: string
  payed!: string
  payments!: Payment[]
  send_email!: boolean
  psc_count!: number
  hours_count!: number
  newInvoiceItem!: IServiceResponse
  discount_type!: string
  discount!: number
  discount_percentage!: number

  transform(data: IInvoiceResponse): void {
    super.transform(data)
    this.newInvoiceItem = {
      title: '',
      amount: null,
      price_per_unit: null,
      total_with_vat: 0,
      total: 0,
      discount_percentage: 0,
      price_per_unit_with_vat: 0,
      unit: '',
      vat: null
    }
    this.add_sale_deed = data.add_sale_deed || false
    this.active = data.active
    this.client = data.client
    this.client_id = data.client_id
    this.author = data.author
    this.manager = data.manager
    this.manager_id = data.manager_id
    this.number = data.number
    this.type = data.type
    this.status = data.status
    this.locale = data.locale
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.due_date = moment(data.due_date).format('YYYY-MM-DD')
    this.total = data.total || '0.00'
    this.total_with_vat = data.total_with_vat || '0.00'
    this.total_vat = data.total_vat || '0.00'
    this.payed = data.payed
    this.leftPay = data.left_pay
    this.items = data.items || [{ ...this.newInvoiceItem }]
    this.notes = data.notes
    this.payments = data.payments
    this.send_email = data.send_email
    this.psc_count = data.psc_count
    this.hours_count = data.hours_count
    this.discount_type = data.discount_type || 'none'
    this.discount = data.discount || 0
    this.discount_percentage = data.discount_percentage || 0
  }
}

export class InvoiceTable extends Model<IModelResponse> {
  static ALIAS: string = 'invoice-table'

  client!: Client
  clientId!: number
  author!: Object
  manager!: any
  managerId!: number
  number!: string
  type!: string
  status!: string
  date!: string
  dueDate!: string
  total!: string
  locale!: string
  totalWithVat!: string
  totalVat!: string
  leftPay!: string
  payed!: string
  items!: Service[]
  notes!: string
  payments!: Payment[]

  transform(data: IInvoiceResponse): void {
    super.transform(data)

    this.client = data.client
    this.clientId = data.client_id
    this.author = data.author
    this.manager = data.manager
    this.managerId = data.manager_id
    this.number = data.number
    this.locale = data.locale
    this.type = data.type
    this.status = InvoiceStatusKeysData[data.status]
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.dueDate = moment(data.due_date).format('YYYY-MM-DD')
    this.total = data.total
    this.totalWithVat = data.total_with_vat
    this.totalVat = data.total_vat
    this.payed = data.payed
    this.leftPay = data.left_pay
    this.items = this.setRelation(Service, data.items)
    this.notes = data.notes
    this.payments = data.payments
  }
}

export class InvoiceSharedTable extends Model<IModelResponse> {
  static ALIAS: string = 'invoice-shared-table'

  client!: Client
  author!: Object
  manager!: any
  number!: string
  type!: string
  status!: string
  date!: string
  dueDate!: string
  total!: string
  totalWithVat!: string
  totalVat!: string
  leftPay!: string
  payed!: string
  notes!: string

  transform(data: IInvoiceResponse): void {
    super.transform(data)

    this.client = data.client
    this.author = data.author
    this.manager = data.manager
    this.number = data.number
    this.type = data.type
    this.status = data.status
    this.date = moment(data.date).format('YYYY-MM-DD')
    this.dueDate = moment(data.due_date).format('YYYY-MM-DD')
    this.total = data.total
    this.totalWithVat = data.total_with_vat
    this.payed = data.payed
    this.leftPay = data.left_pay
    this.notes = data.notes
  }
}
